/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  MTSUContainer,
} from './MTSUElements';

function MTSU() {
  return (
    <MTSUContainer className="MTSUContainer">
    </MTSUContainer>
  );
}

export default MTSU;
