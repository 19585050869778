/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  LLLContainer,
} from './LLLElements';

function LLL() {
  return (
    <LLLContainer className="LLLContainer">
    </LLLContainer>
  );
}

export default LLL;
