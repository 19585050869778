/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  ServingContainer,
} from './ServingElements';

function Serving() {
  return (
    <ServingContainer className="ServingContainer">
    </ServingContainer>
  );
}

export default Serving;
