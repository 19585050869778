/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  WMContainer,
} from './WMElements';

function WM() {
  return (
    <WMContainer className="WMContainer">
    </WMContainer>
  );
}

export default WM;
