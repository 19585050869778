/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const MainContainer = styled.div`
  align-items: center;
  height: 100%;
  widht: 100%;
`;

export const Box = styled.div`
  width: 500px;
  height: 500px;
  background-color: black;
  position: absolute;
`;
