/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  MesiContainer,
} from './MesiElements';

function Mesi() {
  return (
    <MesiContainer className="MesiContainer">
    </MesiContainer>
  );
}

export default Mesi;
