/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  AxiomContainer,
} from './AxiomElements';

function Axiom() {
  return (
    <AxiomContainer className="AxiomContainer">
    </AxiomContainer>
  );
}

export default Axiom;
