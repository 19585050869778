/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  VSCCContainer,
} from './VSCCElements';

function VSCC() {
  return (
    <VSCCContainer className="VSCCContainer">
    </VSCCContainer>
  );
}

export default VSCC;
