/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  NSSContainer,
} from './NSSElements';

function NSS() {
  return (
    <NSSContainer className="NSSContainer">
    </NSSContainer>
  );
}

export default NSS;
