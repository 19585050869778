/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  GratitudeContainer,
} from './GratitudeElements';

function Gratitude() {
  return (
    <GratitudeContainer className="GratitudeContainer">
    </GratitudeContainer>
  );
}

export default Gratitude;
